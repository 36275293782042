import { get, isEmpty, map } from 'lodash-es';
import type { App } from 'vue';
import type { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import BaseLayout from '@/layouts/base-layout/index.vue';
import { createRouterGuard } from './guard';
import { DocumentRoutes, ExceptionRoutes, HarvestRoutes } from './routes';
export interface CustomRecordRaw extends Omit<RouteRecordRaw, 'component'> {
  component?: string;
}

export function getRouteViews(path?: RouteRecordRaw['component'] | string): RouteRecordRaw['component'] {
  const views = import.meta.glob('@/views/**/*.vue');
  return get(views, `/src/views/${path}.vue`) as RouteRecordRaw['component'];
}

export const BaseLayoutRoutes: RouteRecordRaw[] = generateRoutes([
  {
    name: 'home',
    path: '/home',
    component: 'home/index',
    meta: {
      title: 'home',
      i18nKey: 'route.home',
      order: 1,
      icon: 'la:home'
    }
  },
  {
    name: 'about',
    path: '/about',
    component: 'about/index',
    meta: {
      title: 'about',
      i18nKey: 'route.about',
      icon: 'simple-line-icons:info',
      order: 10
    }
  },
  ...DocumentRoutes,
  ...ExceptionRoutes,
  ...HarvestRoutes
]);

export const BlankLayoutRoutes: RouteRecordRaw[] = generateRoutes([
  {
    name: 'login',
    path: '/login/:module(pwd-login|code-login|register|reset-pwd|bind-wechat)?',
    component: 'login/index',
    props: true,
    meta: {
      title: '登录',
      needLogin: false
    }
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component: 'view/404',
    meta: {
      title: 'not-found',
      constant: true
    }
  }
]);

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'root',
      path: '/',
      redirect: '/home',
      component: BaseLayout,
      children: BaseLayoutRoutes
    },
    ...BlankLayoutRoutes
  ]
});

function generateRoutes(routes: CustomRecordRaw[]): RouteRecordRaw[] {
  if (isEmpty(routes)) return [];
  return map(routes, route => {
    return {
      ...route,
      component: getRouteViews(route.component),
      children: generateRoutes((route.children || []) as CustomRecordRaw[]) as RouteRecordRaw[]
    };
  }) as RouteRecordRaw[];
}

/** Setup Vue Router */
export async function setupRouter(app: App) {
  app.use(router);
  createRouterGuard(router);
  await router.isReady();
}
