import { getStorage, removeStorage } from '@/utils/storage';

/** Get token */
export function getToken() {
  return getStorage('token') || '';
}

/** Clear auth storage */
export function clearAuthStorage() {
  removeStorage('token');
  removeStorage('refreshToken');
}
