import type { CustomRecordRaw } from '@/router';

export const ExceptionRoutes: CustomRecordRaw[] = [
  {
    name: '403',
    path: '/403',
    component: '403/index',
    meta: {
      title: '403',
      i18nKey: 'route.403',
      constant: true,
      hideInMenu: true
    }
  },
  {
    name: '404',
    path: '/404',
    component: '404/index',
    meta: {
      title: '404',
      i18nKey: 'route.404',
      constant: true,
      hideInMenu: true
    }
  },
  {
    name: '500',
    path: '/500',
    component: '500/index',
    meta: {
      title: '500',
      i18nKey: 'route.500',
      constant: true,
      hideInMenu: true
    }
  }
];
