import { computed, ref, shallowRef } from 'vue';
import { defineStore } from 'pinia';
import type { RouteKey } from '@elegant-router/types';
import { SetupStoreId } from '@/enum';
import { router } from '@/router';
import { useAppStore } from '../app';
import { getBreadcrumbsByRoute } from './shared';
import { getGlobalMenus } from './menus';

export const useRouteStore = defineStore(SetupStoreId.Route, () => {
  const appStore = useAppStore();

  const routeHome = ref('/home');

  /** Global menus */
  const menus = ref<App.Global.Menu[]>(getGlobalMenus());

  /** Cache routes */
  const cacheRoutes = ref<RouteKey[]>([]);

  /** All cache routes */
  const allCacheRoutes = shallowRef<RouteKey[]>([]);

  /**
   * Add cache routes
   *
   * @param routeKey
   */
  function addCacheRoutes(routeKey: RouteKey) {
    if (cacheRoutes.value.includes(routeKey)) return;

    cacheRoutes.value.push(routeKey);
  }

  /**
   * Remove cache routes
   *
   * @param routeKey
   */
  function removeCacheRoutes(routeKey: RouteKey) {
    const index = cacheRoutes.value.findIndex(item => item === routeKey);

    if (index === -1) return;

    cacheRoutes.value.splice(index, 1);
  }

  /**
   * Is cached route
   *
   * @param routeKey
   */
  function isCachedRoute(routeKey: RouteKey) {
    return allCacheRoutes.value.includes(routeKey);
  }

  /**
   * Re cache routes by route key
   *
   * @param routeKey
   */
  async function reCacheRoutesByKey(routeKey: RouteKey) {
    if (!isCachedRoute(routeKey)) return;

    removeCacheRoutes(routeKey);

    await appStore.reloadPage();

    addCacheRoutes(routeKey);
  }

  /**
   * Re cache routes by route keys
   *
   * @param routeKeys
   */
  async function reCacheRoutesByKeys(routeKeys: RouteKey[]) {
    for await (const key of routeKeys) {
      await reCacheRoutesByKey(key);
    }
  }

  /** Global breadcrumbs */
  const breadcrumbs = computed(() => getBreadcrumbsByRoute(router.currentRoute.value, menus.value));

  /** Reset store */
  async function resetStore() {
    const routeStore = useRouteStore();

    routeStore.$reset();

    // resetVueRoutes();
  }
  /**
   * Get route meta by key
   *
   * @param key Route key
   */
  function getRouteMetaByKey(key: string) {
    const allRoutes = router.getRoutes();

    return allRoutes.find(route => route.name === key)?.meta || null;
  }

  /**
   * Get route query of meta by key
   *
   * @param key
   */
  function getRouteQueryOfMetaByKey(key: string) {
    const meta = getRouteMetaByKey(key);

    const query: Record<string, string> = {};

    meta?.query?.forEach(item => {
      query[item.key] = item.value;
    });

    return query;
  }

  return {
    resetStore,
    routeHome,
    menus,
    cacheRoutes,
    reCacheRoutesByKey,
    reCacheRoutesByKeys,
    breadcrumbs,
    getRouteQueryOfMetaByKey
  };
});
