import { get, isEmpty, some } from "lodash-es";
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  Router,
} from "vue-router";
import { useAuthStore } from "@/store/modules/auth";
import { getToken } from "@/store/modules/auth/shared";

/**
 * create route guard
 *
 * @param router router instance
 */
export function createRouteGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    const loginRoute: string = "/login";
    const noAuthorizationRoute: string = "/403";

    const isLogin = Boolean(getToken());
    const needLogin = to.meta.needLogin !== false;
    const routeRoles = to.meta.roles || [];

    const hasRole = some(get(authStore, "userInfo.roles"), (role) =>
      routeRoles.includes(role),
    );

    const isAdmin = get(authStore, "userInfo.isAdmin");

    const hasAuth = isAdmin || isEmpty(routeRoles) || hasRole;

    const routeSwitches: CommonType.StrategicPattern[] = [
      {
        condition: isLogin && to.path === loginRoute,
        callback: () => {
          next({ name: "home" });
        },
      },
      {
        condition: !needLogin,
        callback: () => {
          handleRouteSwitch(to, from, next);
        },
      },
      {
        condition: !isLogin && needLogin,
        callback: () => {
          next({ path: loginRoute, query: { redirect: to.fullPath } });
        },
      },
      {
        condition: isLogin && needLogin && hasAuth,
        callback: () => {
          handleRouteSwitch(to, from, next);
        },
      },
      {
        condition: isLogin && needLogin && !hasAuth,
        callback: () => {
          next({ name: noAuthorizationRoute });
        },
      },
    ];

    routeSwitches.some(({ condition, callback }) => {
      if (condition) {
        callback();
      }
      return condition;
    });
  });
}

function handleRouteSwitch(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  // route with href
  if (to.meta.href) {
    window.open(to.meta.href, "_blank");
    next({
      path: from.fullPath,
      replace: true,
      query: from.query,
      hash: to.hash,
    });

    return;
  }

  next();
}
