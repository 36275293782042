export type EnvType = "development" | "test" | "production" | "mock" | "local";

export declare interface EnvModule {
  url: string;
  proxyPrefix: string;
}

export interface EnvConfig {
  // 请求环境,与 process.env.VITE_APP_ENV 对应
  env: EnvType;
  // 环境名称
  name: string;
  // 是否默认(选择的)环境
  active?: boolean;
  // 模块
  modules: {
    common: EnvModule;
    [key: string]: EnvModule;
  };
  [key: string]: any;
}

export const envConfigs: EnvConfig[] = [
  {
    env: "development",
    name: "dev环境",
    modules: {
      common: {
        proxyPrefix: "dev",
        url: "https://release-sun-platform.vfiee.cn",
      },
      music: {
        proxyPrefix: "music-dev",
        url: `https://www.shzion.org`,
      },
      zion: {
        proxyPrefix: "zion",
        url: `https://www.shzion.org`,
      },
    },
  },
  {
    env: "test",
    name: "test环境",
    modules: {
      common: {
        proxyPrefix: "test",
        url: "https://release-sun-platform.vfiee.cn",
      },
      music: {
        proxyPrefix: "music-test",
        url: `https://www.shzion.org`,
      },
    },
  },
  {
    env: "production",
    name: "prod环境",
    modules: {
      common: {
        proxyPrefix: "prod",
        url: "https://sun-platform.vfiee.cn",
      },
      music: {
        proxyPrefix: "music-prod",
        url: `https://www.shzion.org`,
      },
    },
  },
];
