import type { StoreDefinition } from 'pinia';
import { defineStore } from 'pinia';
import { first, get, isEmpty } from 'lodash-es';
import type { EnvConfig, EnvType } from '@/config';
import { envConfigs } from '@/config';
import { getStorage, removeStorage, setStorage } from '@/utils/storage';

const { VITE_APP_ENV } = import.meta.env;
const CURRENT_ENV = `${VITE_APP_ENV}_CURRENT_ENV`;
const STORE_USERINFO_KEY = `${VITE_APP_ENV}_STORAGE_USER_INFO`;

const setActiveEnvByAppEnvIfNot = (envs: EnvConfig[]): EnvConfig[] => {
  const index = envs.findIndex(item => item.active);
  if (index !== -1) return envs;
  const envIndex = envs.findIndex(item => item.env === VITE_APP_ENV);
  if (envIndex !== -1) {
    envs[envIndex].active = true;
  }
  return envs;
};

const getEnvs = (): EnvConfig[] => {
  // 从本地存储中获取
  const storageEnv = getStorage<EnvConfig>(CURRENT_ENV)!;
  const index = envConfigs.findIndex(item => item.env === storageEnv?.env);
  if (index !== -1) {
    envConfigs[index] = storageEnv;
  }
  return setActiveEnvByAppEnvIfNot(envConfigs);
};

export type UserStore = ReturnType<StoreDefinition<string, UserStoreState, UserStoreGetters, UserStoreActions>>;
export declare interface UserStoreState {
  keyboardShortcuts: string;
  visible: boolean;
  envs: EnvConfig[];
  userinfo?: Record<string, any>;
}

export declare interface UserStoreActions {
  setEnvVisible: (visible: boolean) => void;
  setEnv: (env: EnvType) => void;
  setUserInfo: (userinfo: Record<string, any> | null) => void;
  // keyboardToggleEnv: (keyboardShortcuts: string) => void
}

export declare interface UserStoreGetters {
  current: (this: UserStore, state: UserStoreState) => EnvConfig;
  isDev: (this: UserStore, state: UserStoreState) => boolean;
  isTest: (this: UserStore, state: UserStoreState) => boolean;
  isProd: (this: UserStore, state: UserStoreState) => boolean;
  isLogin: (this: UserStore, state: UserStoreState) => boolean;
  userHeader: (this: UserStore, state: UserStoreState) => string;
  [key: string]: any;
}

export const useUserStore = defineStore<string, UserStoreState, UserStoreGetters, UserStoreActions>('env', {
  state: () => {
    const modifyKey = `command+shift+alt`;
    return {
      visible: false,
      envs: getEnvs(),
      keyboardShortcuts: `${modifyKey}+c ${modifyKey}+s ${modifyKey}+s`,
      userinfo: getStorage<Record<string, any> | undefined>(STORE_USERINFO_KEY)!
    };
  },
  actions: {
    setEnvVisible(visible: boolean) {
      this.visible = visible;
    },
    setEnv(env: EnvType) {
      this.envs.forEach(item => {
        item.active = item.env === env;
      });
      setStorage(CURRENT_ENV, this.current);
    },
    setUserInfo(userinfo: Record<string, any> | null): void {
      this.userinfo = userinfo!;
      if (userinfo) {
        setStorage(STORE_USERINFO_KEY, userinfo);
      } else {
        removeStorage(STORE_USERINFO_KEY);
      }
    }
  },
  getters: {
    current: state => first(state.envs.filter(item => item.active))!,
    isLogin: (state): boolean => !isEmpty(get(state, 'userinfo.access_token')),
    userHeader(): string {
      return this.isLogin ? `Bearer ${get(this, 'userinfo.access_token')}` : '';
    },
    isDev() {
      return this.current.env === 'development';
    },
    isTest() {
      return this.current.env === 'test';
    },
    isProd() {
      return this.current.env === 'production';
    }
  }
});
