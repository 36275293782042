import { computed, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { defineStore } from 'pinia';
import { SetupStoreId } from '@/enum';
import { useRouterPush } from '@/hooks/common/router';
import { useAxios } from '@/service/useAxios';
import { useRouteStore } from '../route';
import { useTabStore } from '../tab';
import { clearAuthStorage, getToken } from './shared';

export const useAuthStore = defineStore(SetupStoreId.Auth, () => {
  const route = useRoute();
  const routeStore = useRouteStore();
  const tabStore = useTabStore();
  const { toLogin } = useRouterPush(false);
  const token = ref(getToken());

  const userInfo: Api.Auth.UserInfo = reactive({
    userId: '',
    userName: '',
    roles: [],
    buttons: []
  });

  /** is super role in static route */
  const isAdmin = computed(() => userInfo.roles.includes('admin'));

  /** Is login */
  const isLogin = computed(() => Boolean(token.value));

  /** Reset auth store */
  async function resetStore() {
    const authStore = useAuthStore();

    clearAuthStorage();

    authStore.$reset();

    if (route.meta.needLogin !== false) {
      await toLogin();
    }

    tabStore.cacheTabs();
    routeStore.resetStore();
  }

  async function getUserInfo() {
    const { data, execute } = useAxios({ method: 'GET', url: '/user/detail' });
    await execute().catch(console.warn);
    if (data.value) {
      // update store
      Object.assign(userInfo, data.value);

      return true;
    }

    return false;
  }

  async function initUserInfo() {
    const hasToken = getToken();

    if (hasToken) {
      const pass = await getUserInfo();

      if (!pass) {
        resetStore();
      }
    }
  }

  return {
    token,
    userInfo,
    isAdmin,
    isLogin,
    resetStore,
    initUserInfo
  };
});
