<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import mousetrap from 'mousetrap';
import { useUserStore } from '@/store/modules/user';
import type { EnvType } from '@/config';

const userStore = useUserStore();

const current = ref<EnvType>(userStore.current.env);

const title = computed(() => {
  return `切换请求环境(当前: ${userStore.current.name})`;
});

const options = userStore.envs.map(item => ({
  label: item.name,
  value: item.env
}));

const hide = () => {
  userStore.setEnvVisible(false);
};

function onEnvChange(env: EnvType) {
  current.value = env;
}

function onEnvSubmit() {
  userStore.setEnv(current.value);
  hide();
}

onMounted(() => {
  mousetrap.bind(userStore.keyboardShortcuts, () => userStore.setEnvVisible(true));
});

onUnmounted(() => {
  mousetrap.unbind(userStore.keyboardShortcuts);
});
</script>

<template>
  <NModal
    v-model:show="userStore.visible"
    class="toggle-env-wrap"
    preset="card"
    :title="title"
    size="huge"
    :bordered="false"
    :segmented="false"
  >
    <NSelect
      v-model:value="current"
      class="env-select"
      allow-clear
      show-search
      placeholder="请选择环境变量"
      :options="options"
      @update-value="onEnvChange"
    />
    <div class="tips">
      <p>
        切换环境后,后续派发的请求将会是
        <span>{{ current }}</span>
        环境
      </p>
      <p>如果您已经登录,可能需要重新登录(取决于不同环境之间 token 是否互通)</p>
    </div>
    <template #footer>
      <NFlex justify="end">
        <NButton size="large" @click="hide">取消</NButton>
        <NButton type="warning" size="large" @click="onEnvSubmit">确定</NButton>
      </NFlex>
    </template>
  </NModal>
</template>

<style lang="less">
.toggle-env-wrap {
  width: 800px;
  height: 300px;

  .env-select {
    width: 100%;
    margin: 10px 0;
  }

  .tips {
    p {
      margin-top: 10px;
      color: #ffb800;
    }

    span {
      color: red;
    }
  }
}
</style>
