import axios from 'axios';
import { get, set } from 'lodash-es';
import { useAxios as vueUseAxios } from '@vueuse/integrations/useAxios';
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import type { UseAxiosOptions } from '@vueuse/integrations';
import { useUserStore } from '@/store/modules/user';
import { router } from '@/router';

interface CustomAxiosRequestConfig<D = any> extends Partial<InternalAxiosRequestConfig<D>> {
  module?: string;
}

const { PROD } = import.meta.env;
const instance = axios.create();

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // @ts-expect-error 导入module字段
  const { module: requestModule = 'common', url } = config ?? {};
  const { current, isLogin, userHeader } = useUserStore();
  const envModule = get(current, `modules.${requestModule}`);
  if (isLogin) {
    set(config.headers, 'Authorization', userHeader);
  }
  // 如果请求地址以 http 开头,默认不处理(和 axios 保持一致)
  if (url && url.startsWith('http')) return config;
  // 开发环境下走代理
  if (PROD && envModule) {
    config.baseURL = envModule.url || config.baseURL;
  } else if (envModule) {
    config.url = `/${envModule.proxyPrefix}${url}`;
  }
  return config;
});

function onRejected(error: AxiosResponse<any>) {
  const { data, statusText } = error || {};
  const { code, message: businessMessage } = data || {};
  let messageText = businessMessage || statusText || '发生错误';
  if (code === 401) {
    const appStore = useUserStore();
    messageText = '登录超时,请重新登录';
    appStore.setUserInfo(null);
    if (router.currentRoute.value.path !== '/login') {
      router.replace('/login');
    }
  }
  window.$message?.error?.(messageText);
}

instance.interceptors.response.use((response: AxiosResponse<any>) => {
  const data = get(response, 'data');
  if (data?.code === 0) {
    onRejected(response);
    return Promise.reject(data);
  }
  return data;
}, onRejected);

export function useAxios<T = any, R = AxiosResponse<T>, D = any>(
  config: CustomAxiosRequestConfig<D>,
  options?: UseAxiosOptions
) {
  return vueUseAxios<T, R, D>(config?.url as string, config, instance, {
    immediate: false,
    ...(options || {})
  });
}
