import type { CustomRecordRaw } from "@/router";

export const HarvestRoutes: CustomRecordRaw[] = [
  {
    name: "harvest_grace",
    path: "/harvest/grace",
    component: "harvest/grace/index",
    meta: {
      title: "恩典甘露",
      i18nKey: "route.harvest_grace",
      icon: "solar:waterdrops-linear",
    },
  },
  {
    name: "harvest_music",
    path: "/harvest/music",
    component: "harvest/music/index",
    meta: {
      title: "赞美网",
      i18nKey: "route.harvest_music",
      icon: "la:music",
    },
  },
];
